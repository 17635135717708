import { type Listing } from '@prisma/client'
import { clsx, type ClassValue } from 'clsx'
import { type GetSrcArgs, defaultGetSrc } from 'openimg/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useFormAction, useNavigation } from 'react-router'
import { useSpinDelay } from 'spin-delay'
import { extendTailwindMerge } from 'tailwind-merge'
import {
	type CleanFilters,
	type Filters,
} from '#app/routes/($lang)._search+/_layout.tsx'
import { extendedTheme } from './extended-theme.ts'
import { SEARCH_AREAS, type AreaOption } from './providers/autocomplete-data.ts'

export function getUserImgSrc(objectKey?: string | null) {
	return objectKey
		? `/resources/images?objectKey=${encodeURIComponent(objectKey)}`
		: '/img/user.png'
}

export function getImgSrc({
	height,
	optimizerEndpoint,
	src,
	width,
	fit,
	format,
}: GetSrcArgs) {
	// We customize getImgSrc so our src looks nice like this:
	// /resources/images?objectKey=...&h=...&w=...&fit=...&format=...
	// instead of this:
	// /resources/images?src=%2Fresources%2Fimages%3FobjectKey%3D...%26w%3D...%26h%3D...
	if (src.startsWith(optimizerEndpoint)) {
		const [endpoint, query] = src.split('?')
		const searchParams = new URLSearchParams(query)
		searchParams.set('h', height.toString())
		searchParams.set('w', width.toString())
		if (fit) {
			searchParams.set('fit', fit)
		}
		if (format) {
			searchParams.set('format', format)
		}
		return `${endpoint}?${searchParams.toString()}`
	}
	return defaultGetSrc({ height, optimizerEndpoint, src, width, fit, format })
}

export function getOrdinalSuffix(i: number) {
	var j = i % 10,
		k = i % 100

	if (j == 1 && k != 11) {
		return i + 'st'
	}
	if (j == 2 && k != 12) {
		return i + 'nd'
	}
	if (j == 3 && k != 13) {
		return i + 'rd'
	}
	return i + 'th'
}

export function detectLanguage(str?: string) {
	if (!str) return 'Unknown'
	// Regular expression for Greek letters (both lowercase and uppercase)
	const greekRegex = /[\u0370-\u03FF\u1F00-\u1FFF]/

	// Regular expression for English letters (both lowercase and uppercase)
	const englishRegex = /[A-Za-z]/

	// Check if the string contains Greek characters
	if (greekRegex.test(str)) {
		return 'Greek'
	}
	// Check if the string contains English characters
	else if (englishRegex.test(str)) {
		return 'English'
	}
	// If neither, return unknown
	else {
		return 'Unknown'
	}
}

export function parseCityOrAreaURLParam(sentence?: string) {
	if (!sentence || sentence == 'all') return
	// Split the sentence into an array of words
	let words = sentence.split('-')

	// Capitalize the first letter of each word
	let capitalizedWords = words.map((word: string) => {
		return word.charAt(0).toUpperCase() + word.slice(1)
	})

	// Join the words back into a sentence
	let capitalizedSentence = capitalizedWords.join(' ')

	return capitalizedSentence
}

export function capitalizeFirstLetter(s: string) {
	return s.charAt(0).toUpperCase() + s.slice(1)
}

export function nFormatter(num: number) {
	if (num >= 1000000) {
		return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'm'
	} else if (num >= 1000) {
		return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k'
	} else {
		return num
	}
}

export function getFormatedPrice(price: number) {
	if (price === 0) return 'Price not provided'

	return '€' + price.toLocaleString('en-US')
}

export function getNumberOfDays(date1?: Date | null, date2?: Date | null) {
	if (!date1 || !date2) return

	const diffTime: number = Math.abs(date1.getTime() - date2.getTime())
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

	return diffDays
}

export function getAreaOption(
	city?: string | null,
	area?: string | null,
): AreaOption {
	const defaultValue = { id: '', city: '', area: '', label: '', labelGR: '' }

	if (!city) return defaultValue

	return (
		SEARCH_AREAS.find(
			(itm) =>
				itm.city === city && itm.area === (area || 'all').replaceAll('-', ' '),
		) || defaultValue
	)
}

export function getPage(searchParams: URLSearchParams) {
	return { page: Number(searchParams.get('page') || '1') }
}

export function getSearchNavigationParams(filters: Filters) {
	const pathToNavigate = `${filters.saleType}/${filters.propType}/${filters.searchArea.city}/${filters.searchArea.area?.replaceAll(' ', '-') || 'all'}`
	const queryParams = JSON.parse(JSON.stringify(filters)) as CleanFilters

	for (const [key, value] of Object.entries(filters)) {
		if (
			!value ||
			value === 'nomin' ||
			value === 'nomax' ||
			value === 'any' ||
			Array.isArray(value)
		) {
			delete queryParams[key as keyof CleanFilters]
		}

		if (key === 'searchArea' || key === 'saleType' || key === 'propType') {
			delete queryParams[key as keyof CleanFilters]
		}
	}

	const params = new URLSearchParams({ ...queryParams })

	if (filters.condition.length > 0) {
		filters.condition.forEach((itm) => params.append('condition', itm))
	}

	if (filters.heating.length > 0) {
		filters.heating.forEach((itm) => params.append('heating', itm))
	}

	if (filters.extras.length > 0) {
		filters.extras.forEach((itm) => params.append('extras', itm))
	}

	return { pathToNavigate, params }
}

// TODO: add tests make this perfect.
export function filterListings(
	results: Listing[],
	filters: Filters,
): Listing[] {
	let listings = JSON.parse(JSON.stringify(results)) as Listing[]

	const saleType = filters.saleType === 'for-sale' ? 'sale' : 'rent'
	listings = listings.filter((listing) => listing.saleType == saleType)

	if (filters.propType != 'property') {
		listings = listings.filter((listing) => listing.type == filters.propType)
	}

	if (filters.bedsMin != 'nomin') {
		listings = listings.filter(
			(listing) => listing.beds > parseInt(filters.bedsMin),
		)
	}

	if (filters.bedsMax != 'nomax') {
		listings = listings.filter(
			(listing) => listing.beds < parseInt(filters.bedsMax),
		)
	}

	if (filters.priceMin != 'nomin') {
		listings = listings.filter(
			(listing) => Number(listing.price) > parseInt(filters.priceMin),
		)
	}

	if (filters.priceMax != 'nomax') {
		listings = listings.filter(
			(listing) => Number(listing.price) < parseInt(filters.priceMax),
		)
	}

	if (filters.bathsMin != 'nomin') {
		listings = listings.filter(
			(listing) => listing.baths > parseInt(filters.bathsMin),
		)
	}

	if (filters.bathsMax != 'nomax') {
		listings = listings.filter(
			(listing) => listing.baths < parseInt(filters.bathsMax),
		)
	}

	if (filters.extras.includes('parking')) {
		listings = listings.filter((listing) => listing.parking)
	}

	if (filters.extras.includes('pool')) {
		listings = listings.filter((listing) => listing.pool)
	}

	if (filters.extras.includes('garden')) {
		listings = listings.filter((listing) => listing.garden)
	}

	if (filters.extras.includes('fireplace')) {
		listings = listings.filter((listing) => listing.fireplace)
	}
	if (filters.extras.includes('balcony')) {
		listings = listings.filter((listing) => listing.balcony)
	}

	if (filters.condition.includes('newbuilt')) {
		listings = listings.filter((listing) => listing.newHome)
	}

	if (filters.condition.includes('under')) {
		listings = listings.filter((listing) => listing.underconstruction)
	}

	if (filters.condition.includes('existing')) {
		listings = listings.filter((listing) => !listing.newHome)
	}

	if (filters.heating.includes('ac')) {
		listings = listings.filter((listing) => listing.aircondition)
	}

	if (filters.heating.includes('floor')) {
		listings = listings.filter((listing) => listing.underfloor)
	}

	if (filters.heating.includes('heat')) {
		listings = listings.filter((listing) => listing.heating)
	}

	if (filters.added && filters.added == '24h') {
		const oneDay = new Date(Date.now() - 24 * 60 * 60 * 1000)
		listings = listings.filter(
			(listing) =>
				oneDay <= new Date(listing.createdAt) &&
				new Date(listing.createdAt) <= new Date(Date.now()),
		)
	}

	if (filters.added && filters.added == '3d') {
		const threeDays = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
		listings = listings.filter(
			(listing) =>
				threeDays <= new Date(listing.createdAt) &&
				new Date(listing.createdAt) <= new Date(Date.now()),
		)
	}

	if (filters.added && filters.added == '7d') {
		const sevenDays = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
		listings = listings.filter(
			(listing) =>
				sevenDays <= new Date(listing.createdAt) &&
				new Date(listing.createdAt) <= new Date(Date.now()),
		)
	}

	if (filters.added && filters.added == '14d') {
		const fourteenDays = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
		listings = listings.filter(
			(listing) =>
				fourteenDays <= new Date(listing.createdAt) &&
				new Date(listing.createdAt) <= new Date(Date.now()),
		)
	}

	if (filters.added && filters.added == '30d') {
		const thirtyDays = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
		listings = listings.filter(
			(listing) =>
				thirtyDays <= new Date(listing.createdAt) &&
				new Date(listing.createdAt) <= new Date(Date.now()),
		)
	}

	return listings
}

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error
	if (
		error &&
		typeof error === 'object' &&
		'message' in error &&
		typeof error.message === 'string'
	) {
		return error.message
	}
	console.error('Unable to get error message for error', error)
	return 'Unknown Error'
}

function formatColors() {
	const colors = []
	for (const [key, color] of Object.entries(extendedTheme.colors)) {
		if (typeof color === 'string') {
			colors.push(key)
		} else {
			const colorGroup = Object.keys(color).map((subKey) =>
				subKey === 'DEFAULT' ? '' : subKey,
			)
			colors.push({ [key]: colorGroup })
		}
	}
	return colors
}

export const customTwMerge = extendTailwindMerge<string, string>({
	extend: {
		theme: {
			colors: formatColors(),
			borderRadius: Object.keys(extendedTheme.borderRadius),
		},
		classGroups: {
			'font-size': [
				{
					text: Object.keys(extendedTheme.fontSize),
				},
			],
		},
	},
})

export function cn(...inputs: ClassValue[]) {
	return customTwMerge(clsx(inputs))
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ??
		request.headers.get('host') ??
		new URL(request.url).host
	const protocol = request.headers.get('X-Forwarded-Proto') ?? 'http'
	return `${protocol}://${host}`
}

export function getReferrerRoute(request: Request) {
	// spelling errors and whatever makes this annoyingly inconsistent
	// in my own testing, `referer` returned the right value, but 🤷‍♂️
	const referrer =
		request.headers.get('referer') ??
		request.headers.get('referrer') ??
		request.referrer
	const domain = getDomainUrl(request)
	if (referrer?.startsWith(domain)) {
		return referrer.slice(domain.length)
	} else {
		return '/'
	}
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const merged = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			merged.set(key, value)
		}
	}
	return merged
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const combined = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			combined.append(key, value)
		}
	}
	return combined
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
	...responseInits: Array<ResponseInit | null | undefined>
) {
	let combined: ResponseInit = {}
	for (const responseInit of responseInits) {
		combined = {
			...responseInit,
			headers: combineHeaders(combined.headers, responseInit?.headers),
		}
	}
	return combined
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = 'POST',
	state = 'non-idle',
}: {
	formAction?: string
	formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
	state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
	const contextualFormAction = useFormAction()
	const navigation = useNavigation()
	const isPendingState =
		state === 'non-idle'
			? navigation.state !== 'idle'
			: navigation.state === state
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod === formMethod
	)
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
	formAction,
	formMethod,
	delay = 400,
	minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
	Parameters<typeof useSpinDelay>[1] = {}) {
	const isPending = useIsPending({ formAction, formMethod })
	const delayedIsPending = useSpinDelay(isPending, {
		delay,
		minDuration,
	})
	return delayedIsPending
}

function callAll<Args extends Array<unknown>>(
	...fns: Array<((...args: Args) => unknown) | undefined>
) {
	return (...args: Args) => fns.forEach((fn) => fn?.(...args))
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
	const [doubleCheck, setDoubleCheck] = useState(false)

	function getButtonProps(
		props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
	) {
		const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
			() => setDoubleCheck(false)

		const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
			doubleCheck
				? undefined
				: (e) => {
						e.preventDefault()
						setDoubleCheck(true)
					}

		const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] = (
			e,
		) => {
			if (e.key === 'Escape') {
				setDoubleCheck(false)
			}
		}

		return {
			...props,
			onBlur: callAll(onBlur, props?.onBlur),
			onClick: callAll(onClick, props?.onClick),
			onKeyUp: callAll(onKeyUp, props?.onKeyUp),
		}
	}

	return { doubleCheck, getButtonProps }
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
	fn: Callback,
	delay: number,
) {
	let timer: ReturnType<typeof setTimeout> | null = null
	return (...args: Parameters<Callback>) => {
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}

/**
 * Debounce a callback function
 */
export function useDebounce<
	Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
	const callbackRef = useRef(callback)
	useEffect(() => {
		callbackRef.current = callback
	})
	return useMemo(
		() =>
			debounce(
				(...args: Parameters<Callback>) => callbackRef.current(...args),
				delay,
			),
		[delay],
	)
}

export async function downloadFile(url: string, retries: number = 0) {
	const MAX_RETRIES = 3
	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error(`Failed to fetch image with status ${response.status}`)
		}
		const contentType = response.headers.get('content-type') ?? 'image/jpg'
		const arrayBuffer = await response.arrayBuffer()
		const file = new File([arrayBuffer], 'downloaded-file', {
			type: contentType,
		})
		return file
	} catch (e) {
		if (retries > MAX_RETRIES) throw e
		return downloadFile(url, retries + 1)
	}
}

// Method to send events
export function sendGTagEvent(name: string, eventDetails: Record<string, any>) {
	if (typeof window.gtag === 'function') {
		window.gtag('event', name, eventDetails)
	} else {
		console.warn('Google Analytics is not initialized.')
	}
}

export function removePunctuation(str: string): string {
    return str
        .normalize("NFD") // Normalize to decomposed form
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritics (tonos, dialytika, etc.)
        .toLowerCase(); // Convert to lowercase for case-insensitive search
}